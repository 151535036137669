import { doAxiosRequest } from 'config-axios';
import { UPLOAD_LOCAL } from 'constant';
import { action as voiceAction } from 'redux/actions/voice/voice_actions';
import CONFIG from 'config';

const {
  API_URL: {
    LOCAL_UPLOAD
  },
  X_API_KEY: {
    NGEN
  }
} = CONFIG;

export const action = {

  upload: (file,) => async (dispatch) => {
    const formData = new FormData();
    formData.append('audioFile', file);

    const uploadLocalAxiosConfig = {
      method: 'post',
      endpoint: LOCAL_UPLOAD,
      params: formData,
      headers: {
        'x-api-key': NGEN,
        'content-type': 'multipart/form-data',
      }
    };

    dispatch(uploadLocalPending());
    try {
      const uploadLocalResponse = await doAxiosRequest(uploadLocalAxiosConfig);
      dispatch(uploadLocalSuccess(uploadLocalResponse));

      const { getText } = voiceAction;
      dispatch(getText('', file.name, 'localUpload'));
    } catch (error) {
      dispatch(uploadLocalFailure(error?.message));
    }
  }
};

const uploadLocalPending = () => ({
  type: UPLOAD_LOCAL.PENDING,
});

const uploadLocalSuccess = (uploadResult) => ({
  type: UPLOAD_LOCAL.SUCCESS,
  payload: uploadResult,
});

const uploadLocalFailure = (errorMessage) => ({
  type: UPLOAD_LOCAL.FAILURE,
  payload: errorMessage,
});