import { VOICE_TO_TEXT_STREAMING } from 'constant';
import { getFromLocalStorageAsJSON } from 'localStorage';

import CONFIG from 'config';

const {
  API_URL: {
    GET_STREAMING_TEXT
  },
  X_API_KEY: {
    NGEN
  }
} = CONFIG;

export const action = {

  getText: (directory, fileName, from) => async (dispatch) => {
    dispatch(getTextPending());

    const oktaToken = getFromLocalStorageAsJSON(CONFIG.OKTA.OKTA_TOKEN);
    const response = await fetch(GET_STREAMING_TEXT(directory, fileName, from), {
      headers: {
        'x-api-key': NGEN,
        authorization: `Bearer ${oktaToken.accessToken.accessToken}`
      }
    });

    const reader = response.body.getReader();

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const stringValue = new TextDecoder().decode(value);
      dispatch(getTextSuccess(stringValue));
    }
  }
};

const getTextPending = (payload) => ({
  type: VOICE_TO_TEXT_STREAMING.PENDING,
  payload
});

const getTextSuccess = (response) => ({
  type: VOICE_TO_TEXT_STREAMING.SUCCESS,
  payload: response
});

const getTextFailure = (payload) => ({
  type: VOICE_TO_TEXT_STREAMING.FAILURE,
  payload
});