import { OktaAuth } from '@okta/okta-auth-js';
import CONFIG from 'config';

const {
  OKTA: {
    ISSUER,
    CLIENT_ID,
    REDIRECT_URL
  }
} = CONFIG;

const authConfig = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: `${window.location.origin}${REDIRECT_URL}`,
  pkce: false
};

export default new OktaAuth({ ...authConfig });
