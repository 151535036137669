const LABELS = {
  S3_UPLOAD: {
    PAGE_TITLE: 'S3 Upload',
    POPUP_INFO: 'Transcription via file uploaded on S3'
  },
  SERVER_UPLOAD: {
    PAGE_TITLE: 'Server Upload',
    POPUP_INFO: 'Transcription via file uploaded on server'
  },
  FROM_SERVER: {
    PAGE_TITLE: 'From Server',
    POPUP_INFO: 'Transcription from a audio file on server'
  },
  SHARED_COMPONENT: {
    PAGE_TITLE: 'Shared Component',
    POPUP_INFO: 'Transcription via shared component'
  },
  WORKSPACE: {
    PAGE_TITLE: 'InI Workspace',
  },
};

export default LABELS;