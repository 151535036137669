import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  combineReducers, createStore, applyMiddleware, compose
} from 'redux';

import history from 'redux/history';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import {
  name as uploadResultReducerName,
  reducer as uploadResultReducer,
} from './reducers/upload/s3_upload_reducer';

import {
  name as voiceResultReducerName,
  reducer as voiceResultReducer,
} from './reducers/voice/voice_reducer';

import {
  name as localUploadResultReducerName,
  reducer as localUploadResultReducer,
} from './reducers/upload/local_upload_reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [
  thunkMiddleware,
  routerMiddleware(history),
  createLogger({ collapsed: true })
];

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    [uploadResultReducerName]: uploadResultReducer,
    [voiceResultReducerName]: voiceResultReducer,
    [localUploadResultReducerName]: localUploadResultReducer
  }),
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
