const CONFIG = {
  HHMMYYS: '00:00:00.0',
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    REDIRECT_URL: '/implicit/callback' /* Must be whitelisted in the Okta client app settings. */,
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    OKTA_TOKEN: 'okta-token-storage',
    AUTH_ATTEMPTED: 'auth_attempted',
    CACHE_STORAGE: 'okta-cache-storage',
    PSID: 'psid',
  },
  UI_URL: {
    ALL: '*',
    WORKSPACE: '/',
    S3_UPLOAD: '/s3Upload',
    SERVER_UPLOAD: '/serverUpload',
    FROM_SERVER: '/fromServer',
    SHARED_COMPONENT: '/sharedComponent',
  },
  API_URL: {
    GET_PRESIGNED: (filename, dir) => `${process.env.REACT_APP_NGEN_API_URL}/voice/api/v1/s3presignedurl?fileName=${filename}&bucketName=${process.env.REACT_APP_VOICE_BUCKET_NAME}&directory=${dir}`,
    GET_STREAMING_TEXT: (directory, fileName, from) => `${process.env.REACT_APP_NGEN_API_URL}/voice/api/v1/streaming/text?fileName=${fileName}&bucketName=${process.env.REACT_APP_VOICE_BUCKET_NAME}&directory=${directory}&sampleRate=48000&language=en-US&from=${from}`,
    LOCAL_UPLOAD: `${process.env.REACT_APP_NGEN_API_URL}/voice/api/v1/upload`,
    TRANSCRIPTION_SERVICE_URL: `${process.env.REACT_APP_NGEN_API_URL}/voice/api/v1`,
  },
  X_API_KEY: {
    NGEN: process.env.REACT_APP_NGEN_X_API_KEY,
  },
  ENV: process.env.REACT_APP_ENV,
};

export default CONFIG;