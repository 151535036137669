import CONFIG from 'config';

export function getFromLocalStorageAsArray(key) {
  return !(localStorage.getItem(key)) ? [] : JSON.parse(localStorage.getItem(key));
}

export function getFromLocalStorageAsJSON(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function sendToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export function clearAuthCache() {
  removeFromLocalStorage(CONFIG.OKTA.CACHE_STORAGE);
  removeFromLocalStorage(CONFIG.OKTA.OKTA_TOKEN);
}

export function getSecondsSinceLastAttempt() {
  const lastRetryTime = new Date(getFromLocalStorageAsJSON(CONFIG.OKTA.AUTH_ATTEMPTED));
  const now = new Date();
  const durationInMs = now - lastRetryTime;
  return durationInMs / 1000;
}

export function checkDurationAndReload(durationInSeconds) {
  if (getSecondsSinceLastAttempt() < durationInSeconds) {
    clearAuthCache();
    window.location.reload();
  }
}

export function setAuthAttemptTime() {
  sendToLocalStorage(CONFIG.OKTA.AUTH_ATTEMPTED, new Date());
}

export function isAuthAttemptSet() {
  return getFromLocalStorageAsJSON(CONFIG.OKTA.AUTH_ATTEMPTED) !== null;
}

export function getLoggedInUserHrId() {
  const OKTA_TOKEN = getFromLocalStorageAsJSON(CONFIG.OKTA.OKTA_TOKEN);
  const ACCESS_TOKEN = OKTA_TOKEN?.accessToken?.accessToken;

  if (ACCESS_TOKEN) {
    const tokenAToB = decodeAccessToken(ACCESS_TOKEN);
    return tokenAToB?.hrid;
  }
}

/**
 * @desc decode JWT token
 * @param {String} token
 * @return Object
 */
export function decodeAccessToken(token) {
  return JSON.parse(atob(token.split('.')[1]));
}

export function setLoggedInUserImagePath(loggedInUserImagePath) {
  sendToLocalStorage(CONFIG.PEOPLE_API.LOGGEDIN_USER_IMAGE_PATH, loggedInUserImagePath);
}

export function getLoggedInUserImagePath() {
  return getFromLocalStorageAsJSON(CONFIG.PEOPLE_API.LOGGEDIN_USER_IMAGE_Path);
}

export function removeLoggedInUserImagePath() {
  removeFromLocalStorage(CONFIG.PEOPLE_API.LOGGEDIN_USER_IMAGE_PATH);
}

export function getLoggedInUserEmail() {
  const OKTA_TOKEN = getFromLocalStorageAsJSON(CONFIG.OKTA.OKTA_TOKEN);
  const ID_TOKEN = OKTA_TOKEN?.idToken;
  const CLAIMS = ID_TOKEN?.claims;
  return CLAIMS?.email ?? '';
}