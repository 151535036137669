import React, { PureComponent } from 'react';
import history from 'redux/history';
import { Provider } from 'react-redux';
import auth from 'redux/auth';
import store from 'redux/store';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import ImplicitCallbackWrapper from 'components/ImplicitCallback/ImplicitCallback';
import CONFIG from 'config';
import { Workspace } from 'containers/Workspace';
import {
  S3Upload, ServerUpload, FromServer, SharedComponent
} from 'containers/Transcription';

const {
  UI_URL: {
    WORKSPACE,
    S3_UPLOAD,
    SERVER_UPLOAD,
    FROM_SERVER,
    SHARED_COMPONENT
  },
  OKTA: {
    REDIRECT_URL
  }
} = CONFIG;

export class App extends PureComponent {
  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Security oktaAuth={auth} restoreOriginalUri={this.restoreOriginalUri}>
            <Switch>
              <SecureRoute
                exact
                path={WORKSPACE}
                component={Workspace}
              />
              <SecureRoute
                exact
                path={S3_UPLOAD}
                component={S3Upload}
              />
              <SecureRoute
                exact
                path={SERVER_UPLOAD}
                component={ServerUpload}
              />
              <SecureRoute
                exact
                path={SHARED_COMPONENT}
                component={SharedComponent}
              />
              <SecureRoute
                exact
                path={FROM_SERVER}
                component={FromServer}
              />
              <Route
                path={REDIRECT_URL}
                component={ImplicitCallbackWrapper}
              />
            </Switch>
          </Security>
        </ConnectedRouter>
      </Provider>
    );
  }
}