import React, { Component } from 'react';
import { Layout } from 'containers/Layout';
import { TextBox } from 'components/TextBox/TextBox';
import { TextboxTranscribe } from '@enrich/voice-transcribe';
import LABELS from 'labels';
import CONFIG from 'config';
import { getFromLocalStorageAsJSON } from '../../localStorage';

import './SharedComponent.scss';

const {
  SHARED_COMPONENT: {
    PAGE_TITLE
  }
} = LABELS;

const {
  OKTA: {
    OKTA_TOKEN
  },
  API_URL: {
    TRANSCRIPTION_SERVICE_URL
  },
  X_API_KEY: {
    NGEN
  },
  ENV
} = CONFIG;

export class SharedComponent extends Component {
  state = {
    isRecording: false,
    transcribedText: '',
    textboxDisabled: false,
    partialStabilizedTranscribedText: '',
  };

/**
 * 
 * @param {String} text - transcrbed text
 * @param {boolean} isPartial - wthether the transcribed text is stabilized or not
 */
  setTranscribedText = (text, isPartial) => {
    let interimText = this.state.partialStabilizedTranscribedText + ' ' + text;

    if(isPartial === false) {
      this.setState({
        partialStabilizedTranscribedText: interimText
      })
    }

    this.setState({
      transcribedText: interimText,
      textboxDisabled: isPartial,
    });
  }

  setText = (evt) => {
    this.setState({
      transcribedText: evt.target.value,
      partialStabilizedTranscribedText: evt.target.value
    });
  }

  render() {
    const { isRecording, transcribedText, textboxDisabled } = this.state;
    const oktaToken = getFromLocalStorageAsJSON(OKTA_TOKEN);
    const {
      accessToken: {
        accessToken
      }
    } = oktaToken;

    const apiConfig = {
      url: TRANSCRIPTION_SERVICE_URL,
      clientToken: `Bearer ${accessToken}`,
      xApiKey: NGEN
    };

    const paramConfig = {
      docId: '2feppwe-2ne23f2-f2d232-wencds',
      fieldName: 'demo-description',
      type: 'textbox' // can be search as well
    };

    return (
      <Layout
        id="voice"
        layoutClass="layout"
        pageTitle={PAGE_TITLE}
      >
        <div className="shared-component">
          <TextBox
            isRecording={isRecording}
            showStart={false}
            transcribedText={transcribedText}
            onTextChange={this.setText}
            disabled={textboxDisabled}
          />
          <TextboxTranscribe
            transcribedTextCallback={this.setTranscribedText}
            apiConfig={apiConfig}
            paramConfig={paramConfig}
          />
        </div>
      </Layout>
    );
  }
}
