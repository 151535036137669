// React
import LABELS from 'labels';
import { Layout } from 'containers/Layout';
import { WorkspaceLinks } from 'components/WorkspaceLinks/WorkspaceLinks';
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Workspace.scss';

const {
  S3_UPLOAD: {
    PAGE_TITLE: S3_PAGE_TITLE,
    POPUP_INFO: S3_PAGE_INFO
  },
  SERVER_UPLOAD: {
    PAGE_TITLE: SERVER_PAGE_TITLE,
    POPUP_INFO: SERVER_PAGE_INFO
  },
  FROM_SERVER: {
    PAGE_TITLE: FROM_SERVER_PAGE_TITLE,
    POPUP_INFO: FROM_SERVER_PAGE_INFO
  },
  SHARED_COMPONENT: {
    PAGE_TITLE: SHARED_PAGE_TITLE,
    POPUP_INFO: SHARED_PAGE_INFO
  }
} = LABELS;

export class Workspace extends React.PureComponent {
  render() {
    return (
      <Layout
        id="workspace"
        layoutClass="layout"
      >
        <Container fluid>
          <Row>
            <Col md={6}>
              <div className="workspace__label__position">
                <span className="workspace__label">{LABELS.WORKSPACE.PAGE_TITLE}</span>
              </div>
              <NavLink to="/s3Upload">
                <WorkspaceLinks
                  labelName={S3_PAGE_TITLE}
                  labelPopupInfo={S3_PAGE_INFO}
                />
              </NavLink>
              <NavLink to="/serverUpload">
                <WorkspaceLinks
                  labelName={SERVER_PAGE_TITLE}
                  labelPopupInfo={SERVER_PAGE_INFO}
                />
              </NavLink>
              <NavLink to="/fromServer">
                <WorkspaceLinks
                  labelName={FROM_SERVER_PAGE_TITLE}
                  labelPopupInfo={FROM_SERVER_PAGE_INFO}
                />
              </NavLink>
              <NavLink to="/sharedComponent">
                <WorkspaceLinks
                  labelName={SHARED_PAGE_TITLE}
                  labelPopupInfo={SHARED_PAGE_INFO}
                />
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}