/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import CONFIG from 'config';
import LABELS from 'labels';

import { getFromLocalStorageAsJSON } from 'localStorage';

export const doAxiosRequest = async (request) => {
  const {
    method, endpoint, headers, params, responsetype, file
  } = request;
  if (method === 'put') {
    const options = {
      headers
    };
    return await axios.put(endpoint, file, options);
  }
  return await axios({
    method,
    url: endpoint,
    headers,
    data: params,
    responseType: responsetype,
  });
};

// You can specify config defaults that will be applied to every request.
// axios.defaults.baseURL = CONFIG.SERVER_URL;

/* You can intercept requests or responses before they are handled by then or catch. */

// This is a request interceptor
axios.interceptors.request.use(
  (config) => {
    const oktaToken = getFromLocalStorageAsJSON(CONFIG.OKTA.OKTA_TOKEN);
    if (!oktaToken) {
      window.location.reload();
      return Promise.reject(
        new axios.Cancel(LABELS.ERROR_MESSAGES.AUTH_FAILED)
      );
    }
    let authHeader = {};
    if (typeof config.headers.noAuthHeader === 'undefined') { // send no bearer token at all if noAuthHeader is set true
      const conditionHeader = config.headers.customAuthHeader !== 'undefined' && !!config.headers.customAuthHeader;
      authHeader = {
        [conditionHeader ? 'specifiedAuthorization' : 'authorization']: `Bearer ${oktaToken.accessToken.accessToken}`
      };
      if (conditionHeader) {
        delete config.headers.authorization;
      }
    }
    // Do something before request is sent
    return {
      ...config,
      headers: {
        ...config.headers,
        ...authHeader,
      }
    };
  },
  // Do something with request error
  (error) => Promise.reject(error)
);

// This is a response interceptor
// axios.interceptors.response.use(
//   response => {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     switch (response.status) {
//       default:
//         console.log(`response.data` + response.data);
//         return response.data;
//     }
//   },
//   error => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (error.response) {
//       switch (error.response.status) {
//         case 500:
//           return Promise.reject({ message: LABELS.ERROR_MESSAGES.SERVER_ERROR, error });
//         case 401:
//           return Promise.reject(new Error(LABELS.ERROR_MESSAGES.AUTH_FAILED));
//         case 403:
//           return Promise.reject({ message: LABELS.ERROR_MESSAGES.FORBIDDEN, error });
//         case 404:
//           return Promise.reject({ message: LABELS.ERROR_MESSAGES.NOT_FOUND, error });
//         default:
//           return Promise.reject(new Error(LABELS.ERROR_MESSAGES.UNKNOWN_CODE, error.response));
//       }
//     } else if (error.request) {
//       return Promise.reject(new Error(LABELS.ERROR_MESSAGES.NETWORK_ERROR, error.request));
//     } else {
//       return Promise.reject({ cancelled: true });
//     }
//   }
// );
