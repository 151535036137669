import {
  UPLOAD_S3, PRESIGNURL, SUCCESS, ERROR
} from 'constant';

export const name = 'upload';

export const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  uploadStatus: '',
  presignurlStatus: ''
};

// reducer selectors
export const selector = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getUploadStatus: (state) => state[name].uploadStatus,
  getPresignurlStatus: (state) => state[name].presignurlStatus
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case PRESIGNURL.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        presignurlStatus: ERROR
      };
    case PRESIGNURL.PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case PRESIGNURL.SUCCESS:
      return {
        ...state,
        loading: false,
        presignurlStatus: SUCCESS,
      };
    case UPLOAD_S3.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        uploadStatus: ERROR
      };
    case UPLOAD_S3.PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case UPLOAD_S3.SUCCESS:
      return {
        ...state,
        loading: false,
        uploadStatus: SUCCESS,
      };
    default:
      return state;
  }
}