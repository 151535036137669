import React from 'react';

import './TextBox.scss';

export function TextBox(props) {
  const {
    disabled,
    isRecording,
    transcribedText,
    fileName,
    fileUrl,
    showStop,
    showStart,
    startRecording,
    stopRecording,
    onTextChange
  } = props;
  return (
    <div className="transcribe__style">
      <div>
        <textarea rows="5" cols="100" disabled={disabled} value={transcribedText} placeholder="Your transcribed text will appear here" onChange={onTextChange} />
        <div>
          {
            showStart
            && (
            <button disabled={isRecording} onClick={startRecording}>
              START
            </button>
            )
          }
          {
            showStop
            && (
            <button disabled={!isRecording} onClick={stopRecording}>
              STOP
            </button>
            )

          }
          {
            !isRecording && !!fileName && !!fileUrl && <a href={fileUrl} download={fileName}>Download</a>
          }
        </div>
      </div>
    </div>
  );
}

// Setting default value for props
TextBox.defaultProps = {
  showStart: true
};